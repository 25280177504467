.processing {
    display: flex;
    flex-direction: column; /* Positionner les éléments verticalement */
    justify-content: center; /* Centrer verticalement */
    align-items: center; /* Centrer horizontalement */
    height: 20vh; /* Ajuster la hauteur selon vos besoins */
    text-align: center;
    font-weight: bolder;
    font-family: "Amiri", serif;
    font-style: normal;
    font-size: xx-large;
    color: #002b45eb;
    padding: 100px;
}

.processing h1 {
    margin-top: 20px;
}
