footer {
    background-color: #002b45eb;
    color: white;
    padding: 10px 0;
    font-weight: bold;
    direction: rtl;
    width: 100%;
    box-sizing: border-box;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    padding-bottom: 0;

}

.LISTE {
    color: white;
    font-weight: bolder;
    font-family: "Amiri", serif;
    font-style: normal;
    line-height: 1,9;
    margin-top: 10px;
}

.INSCRIT{
    margin-top: 25px;
    margin-bottom: 10px;

}


.grid-item {
    font-size: 1.2em;
    border-radius: 5px;
}

.logofooter {
    height: 190px;
    width: auto;
    display: block;
    margin: 0 auto;
}

.grid-item h3 {
    font-weight: bolder;
    font-family: "Amiri", serif;
    font-style: normal;
    text-align: center;
    color: #FFD358;
    font-size: 1.6em;
    padding-bottom: 30px;
}

.grid-item p {
    font-weight: bolder;
    font-family: "Amiri", serif;
    font-style: normal;
    font-weight: bold;

}
.grid-item i {
    color: #FFD358;
    font-size: 1.4em;
}

.separator {
    width: 100%;
    color: white;
    height: 2px;
    margin-bottom: -150px;

}


.grid-item:nth-child(4) {
    gap: 110px; /* Adds space between grid items */
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    margin-top: -65px;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 10px;
    grid-column: 1 / -1; /* Span the item across all columns */
}

.footer2 {
    width: 100%; /* Ensure footer2 takes full width */
    text-align: center;
    margin-top: 20px;
    background-color: white; /* Background color for footer2 */
    padding: 1em; /* Add padding for spacing */
    margin-bottom: -20px;
}

.footer2 span {
    color:  #FFD358;
}

input
 {  
    font-size: 1em;
    padding: 1em;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 5px;
    font-weight: bolder;
    font-family: "Amiri", serif;
    font-style: normal;
    color: gray;
}

.grid-item button {
    background-color: #FFD358;
    color: #ffffff;
    font-size: 1em;
    padding: 1em;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 5px;
    font-weight: bolder;
    font-family: "Amiri", serif;
    font-style: normal;
}

.grid-item button:hover {
    background-color: transparent;
    border: 1px solid white;

}
.footer2 p {
    margin: 0; /* Remove any default margin */
    font-weight: bolder;
    font-family: "Amiri", serif;
    font-style: normal;
    font-size: larger;
    color: #002b45eb;
}

@media (max-width: 1000px) {
    .grid-container {
        grid-template-columns: 1fr;

    }
    
    
}


@media (max-width: 450px) {
   
    .SOCIALTEXT{
        display: none;  }    
    
    .grid-item:nth-child(4) {
        font-size: 15px;
        gap: 40px;
        margin-top: -30px;

    }
    footer {
        padding: 0px 0;
    }
    
.separator {
    width: 100%;
    color: white;
    margin-bottom: -50px;
}
.footer2 {
    font-size: 14px;}

.grid-item h3 {
    padding-bottom: 0px;
    font-size: 20px;
}


.logofooter {
    height: 100px;   
}

input
 {  
    font-size: 15px;
   
}

.grid-item button {
    font-size: 15px;
    
}
}
