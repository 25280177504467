/* Categorie.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";



.button-container {
  color: transparent;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 40px;
}

.buttoncategorie{
    cursor: pointer;
    width: 200px;
    height: 100px;
    background-color: rgba(0, 43, 69, 0.5);
    border: 2px solid rgba(0, 43, 69, 1);
    border-radius: 10px;
    color:rgb(76, 86, 91);
    font-weight: bolder;
    font-family: "Amiri", serif;
    font-style: normal;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.circle-button {
  box-shadow:  0px 4px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100px;
  border-radius: 10px;
  background-color:rgba(0, 43, 69, 0.813);
  border: 2px solid rgba(0, 43, 69, 1); 
  color: white;
  font-size: 20px;
  font-weight: bold;
  font-weight: bolder;
  font-family: "Amiri", serif;
  font-style: normal;

}

.section-title {
  display: flex;
  margin-top: 40px;
  font-size: 36px;
  font-weight: bold;
  font-family: "Amiri", serif;
  font-style: normal;
  align-items:center;
  justify-content: center;
  color: rgba(0, 43, 69, 1); ;
}

.section-title .iconCategorie{
  margin-right: 600px;

}



@media (max-width: 1000px) {
  .section-title .iconCategorie{
    margin-right: 100px;
  }

 
}

@media (max-width: 600px) {
  .section-title .iconCategorie{
    margin-right: 60px;
  
  }
  .section-title {
    font-size: 25px;
  }

  
.button-container {
  gap: 5px;
  padding: 15px;
}

.circle-button {
  font-size: 15px;
  width: 90px;
  height: 40px;
}

}