.Libary{
    text-align: center;
    height: auto;
    justify-content: center;
  
    }
  
  

  .containerLibary {
    padding: 40px;
    display: grid; /* Utilisation de CSS Grid */
    grid-template-columns: repeat(4, 1fr); /* 2 colonnes égales par ligne */
    row-gap: 20px;
    column-gap: 20px; /* Espacement entre les éléments */
    justify-items: center; /* Centrage horizontal des éléments dans la grille */
    align-items: center; /* Centrage vertical des éléments dans la grille */
    width: auto;
    margin-left:  auto ;
    margin-right: auto;

    
  }
  
  .boxLibary {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    width: 300px;
    height: 130px;
    background-color: rgba(0, 43, 69, 0.9);
    border: 2px solid rgba(0, 43, 69, 1);
    border-radius: 10px;
    color: white;
    font-weight: bolder;
    font-family: "Amiri", serif;
    font-style: normal;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;


  }
  
  .buttonLibary {
    padding: 10px 20px;
    cursor: pointer;
    width: 300px;
    height: 130px;
    background-color: rgba(0, 43, 69, 0.5);
    border: 2px solid rgba(0, 43, 69, 1);
    border-radius: 10px;
    color:rgba(0, 43, 69, 1);
    font-weight: bolder;
    font-family: "Amiri", serif;
    font-style: normal;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
@media (max-width: 1330px) {
    .containerLibary {
       justify-content: center;
        align-items: center; /* Centrage vertical des éléments dans la grille */
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        grid-template-columns: repeat(3, 1fr); /* 2 colonnes égales par ligne */
      }
  }
  @media (max-width: 990px) {
    .containerLibary {
       justify-content: center;
        align-items: center; /* Centrage vertical des éléments dans la grille */
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        grid-template-columns: repeat(2, 1fr); /* 2 colonnes égales par ligne */
      }
  }

  @media (max-width: 660px) {
    .containerLibary {
      justify-content: center;
        align-items: center; /* Centrage vertical des éléments dans la grille */
        grid-template-columns: repeat(2, 1fr); /* 2 colonnes égales par ligne */
    }

    .boxLibary {
        width: 170px;
        height: 70px;
        font-size: 25px;
        
      }


      
       
  }

  @media (max-width: 380px) {
    .containerLibary {

      row-gap: 5px;    
      grid-template-columns: repeat(2, 1fr); /* 2 colonnes égales par ligne */

    }
    .boxLibary {
      width: 100px;
      height: 60px;
      font-size: 15px;

      
    }

    .buttonLibary {
      padding: 10px 20px;
      width: 250px;
      height: 130px;
  
    }
  }