.Suggestion {
    padding: 20px;
    margin-bottom: 60px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.port-item1{
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    margin: 15px;
    border-radius: 5px;

    font-weight: bolder;
    font-family: "Amiri", serif;
    font-style: normal;
    font-size: larger;
    color: #002b45eb;
}
.port-item1 img{
    width: 100%;
    height: 200px;
    
}

.Suggestion-title {
    font-size: 30px;
    font-weight: bolder;
    font-family: "Amiri", serif;
    font-style: normal;   
     margin-bottom: 20px;
    text-align: center;
    color: #FFD358;
  }


@media (max-width: 627px) {
    .port-item1{
        margin: 50px;
    }
    .port-item1 img{
        height: 250px;
        
    }
  }
