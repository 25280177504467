@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.list-reset {
  font-weight: bolder;
  font-family: "Amiri", serif;
  font-style: normal;
  font-size: larger;
  color: #002b45eb;
}

.selected {
  color: #ffd358;
  font-weight: bold;
}

.search-bar {
  display: none;
}

.search1 {
  cursor: pointer; /* Ajoutez un curseur indiquant que c'est cliquable */
  padding-top: 13px;
}

.nav1 {
  height: 50px;
  background-color: #002b45eb;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 50px;
  padding-right: 10px;
  font-family: "Amiri", serif;
  font-style: normal;
  font-size: 13px;
  font-weight: lighter;
}

.vertical-line {
  border: none; /* supprime la bordure par défaut */
  border-left: 1px solid white; /* épaisseur, style et couleur de la ligne */
  height: 39px; /* hauteur de la ligne */
}

.nav12 input {
  border-radius: 10px;
  margin-right: 10px;
  margin-left: -40px;
  padding: 5px 10px 5px; /* Haut, droite, bas */
  width: 300px;
  height: auto;
  color: gray;
  caret-color: gray; /* Appliquer caret-color transparent seulement au texte */

}

.nav12 {
  direction: rtl;
  display: flex;
  flex-direction: row;
}

.nav11 {
  direction: rtl;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.nav13 {
  padding-right: 10px;
  padding-left: 10px;
}

.TITLE {
  font-size: 30px;
  font-weight: bold;
  color: #002b45eb;
  font-family: "Amiri", serif;
  font-weight: 700;
  font-style: italic;
}

.sous-title{
  font-size: 20px;
  font-weight: bold;
  color: #002b45eb;
  font-family: "Amiri", serif;
  font-weight: 700;
  font-style: italic;
  letter-spacing: 0.1px;
}

.logo {
  width: 115px;
  height: auto;
  padding: 5px;
}
.search {
  display: none;
}
.titre {
  display: flex;
  align-items: center;
}

.nav-responsive {
  justify-content: center;
  align-items: center;
  display: flex !important;
  flex-direction: column;
  padding-top: 20px;
  text-align: center;
}

.icon {
  color: #ffd358;
}

@media (max-width: 1024px) {
  .titre {
    flex-grow: 1;
  }
  .TITLE {
    font-size: 25px;
  }
}
@media (max-width: 356px) {
  .TITLE {
    font-size: 18px;
  }

  .titre {
    margin-right: -20px;
  }

  .nav12 input {
    display: none;
  }
  .search1{
    display: none;
  }

  .nav12 .search {
    display: block;
    background-color: #ffd358;
    border: 1px solid WHITE;
    border-radius: 70px;
    width: 20px;
    height: auto;
    padding: 10px;
    margin-left: -40px;
    cursor: pointer; /* Ajoutez un curseur indiquant que c'est cliquable */
  }
  .search-bar {
    direction: rtl;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background: rgba(0, 0, 0, 0.717);
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    overflow: auto;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100%;
    display: flex; /* Utilisation du flexbox pour aligner les éléments */
    align-items: center; /* Centrer verticalement les éléments */
  }

  .search-input {
    caret-color: gray; /* Appliquer caret-color transparent seulement au texte */
    cursor: pointer; /* Ajoutez un curseur indiquant que c'est cliquable */
    border: none;
    border-bottom: 1px solid white; /* Ajout de la ligne sous l'input */
    flex: 1; /* L'input prend tout l'espace restant */
    padding: 10px;
    margin-left: 10px; /* Espacement entre l'icône et l'input */
    background: none; /* Retirer le fond de l'input */
    color: white; /* Couleur du texte */
    outline: none;
  }

  .closesearch {
    color: white;
    font-size: 30px;
    cursor: pointer;
    padding: 10px;
  }
}

@media (max-width: 815px) {
  .nav12 input {
    width: 150px;
  }
}

@media (max-width: 660px) {
  .nav13 {
    align-items: center;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 5px;
    padding-right: 9px;
    padding-left: 5px;
  }

  .text {
    display: none; /* Masque le texte */
  }

  .icon {
    height: 20px;
  }

  .nav11 {
    border: 1px solid white;
    border-radius: 10px;
  }

  .logo {
    width: 80px;
    height: auto;
  }
}
