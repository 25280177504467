/* Portfolio.css */
/* ================================================= */
/* ------------------ General CSS ------------------ */
/* ================================================= */

.title {
  padding: 10px;
  font-family: "Amiri", serif;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  font-weight: 700;
  color: #002b45eb;
  letter-spacing: 1px;
  line-height: 2;
}

.titlekeyword {
  font-family: "Amiri", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  font-weight: 700;
  color: #002b45eb;
  letter-spacing: 1px;
  line-height: 1;}



.pagination {
  justify-content: center;
  margin-bottom: 20px;
  direction: ltr;
}

.no-keywords {
  font-family: "Amiri", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  font-weight: 700;
  color: black;
  letter-spacing: 1px;
  line-height: 2;
  margin-top: -10px;
}

.pagination li a {
  text-decoration: none;
  color: #002b45eb;
  /* Couleur par défaut des numéros de pagination */
  transition: background-color 0.3s, color 0.3s;
  /* Ajout de la transition */
}

.pagination li.active a {
  background-color: #002b45eb;
  color: #fff;
  border-color: #002b45eb;

}




/* Styles spécifiques à la page Portfolio */
#portfolio {
  max-width: 1200px;
  /* Limite la largeur du conteneur principal */
  margin: 0 auto;
  /* Centre le conteneur horizontalement */
  padding-left: 20px;
  /* Marge intérieure à gauche */
  padding-right: 20px;
  /* Marge intérieure à droite */
  direction: rtl;

}

.row-image {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}



.section-header {
  text-align: center;
  margin-bottom: 50px;
}

.section-header h2 {
  font-size: 36px;
  font-weight: 700;
  color: #333;
}

.port-item {
  padding: 15px;
  border-radius: 5px;
}

.box7 {
  position: relative;

}

.box7 img {
  width: 100%;
  /* ou la largeur que vous souhaitez */
  height: 300px;
}

.section-header {
  text-align: center;
}

.section-header h2 {
  font-size: 36px;
  font-weight: 700;
  color: #333;
}




.box7 .box-content {
  width: 100%;
  padding: 20px;
  text-align: center;
  color: #fff;
  transition: all 0.3s ease-in-out;
  display: flex;
  text-align: center;
  justify-content: center;



}

/* Icons */
.box7 .icon {
  cursor: pointer;
  display: flex;
  position: absolute;
  bottom: 10px;
  top: 0;
  left: 100px;
  right: 60px;
  align-items: center;
  text-align: center;
  justify-content: center;

}

.box7 .icon li {

  margin: 0 3px;
}

.box7 .icon li i {

  text-decoration: none;
  /* Add this line to remove underline */
  color: #fff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  background: #002b45eb;
  font-size: 18px;
  transition: all 0.5s ease 0s;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;


}

.icon li i.red-heart {
  color: red !important;
}

.box7 .icon li i:hover {
  color: #FFD358;
  transform: rotate(360deg);
}

.box7:hover:after {
  left: -170%;
}

.box7:hover:before {
  opacity: 0.5;
}

.box7:after,
.box7:before {
  width: 100%;
  height: 100%;
  background: rgba(11, 33, 47, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease 0s;
  content: ""
}

.box7:after {
  background: rgba(255, 255, 255, 0.3);
  border: 2px solid #002b45eb;
  top: 0;
  left: 170%;
  opacity: 1;
  z-index: 1;
  transform: skewX(45deg);
  transition: all 1s ease 0s;
}

.box7 .title {
  text-transform: uppercase;
}

.box7 {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}



.box7 .box-content {
  position: absolute;
  bottom: 0%;
}

.box7:hover .box-content {
  bottom: 10%;
}

.modal .modal-header {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  gap: 7px;

}

.close {
  font-size: 25px;
  color: #002b45eb;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFD358;
  border-radius: 10%;
  height: 40px;
  width: 50px;
}

.bookmark {
  font-size: 30px;
  color: #002b45eb;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b1bbb1;
  border-radius: 10%;
  height: 40px;
  width: 50px;

}

.modal .modal-body h2 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.modal .modal-body .download-content {
  font-size: 14px;
  border: #d0d7d0 solid 1px;
  padding: 10px;
  border-radius: 5px;
}

.post {
  font-style: normal;
  font-weight: bolder;
  font-family: "Amiri", serif;
  color: black;

}

.box-content {
  direction: rtl;
}


.modal .modal-body .download-content button {
  font-weight: bolder;
  font-family: "Amiri", serif;
  font-style: normal;
  display: inline-block;
  padding: 8px 15px;
  background: #002b45eb;
  border-radius: 4px;
  color: #FFD358;
  cursor: pointer;
  border: none;
  margin-right: 10px;
}

.modal .modal-body .download-content button:hover {
  color: #002b45eb;
  background: white;
  border: 1px solid #002b45eb;
}

.modal .modal-body .download-content button i {
  margin-right: 5px;
}


.button-container1 {
  direction: rtl;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  gap: 5px;
}

.circle-button1 {
  padding: 4px;
  font-weight: bold;
  font-family: "Amiri", serif;
  font-style: normal;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 43, 69, 0.251);
  color: #002b45eb;
  width: auto;
  height: 30px;
  font-size: 15px;
  font-family: "Amiri", serif;
  font-style: normal;
  text-align: center;
  margin-bottom: 10px;

}



.share-icons {
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;

  .SHAREtitre {
    font-family: "Amiri", serif;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    color: #002b45eb;
    letter-spacing: 1px;
    line-height: 2;
  }

  .iconsshare {
    gap: 10px;
    /* Espacement entre les icônes */
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    >*:hover {
      opacity: 0.5;
      /* Opacité réduite au survol */
      transform: scale(1.1);
      /* Zoom */
      transition: all 0.3s ease-in-out;

    }
  }




}

#collection-id.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

#collection-id .modal-dialog {
  max-width: 500px;
}

#collection-id .modal-content {
  border-radius: 10px;
  overflow: hidden;
}

#collection-id .modal-header {
  border-bottom: none;
  padding: 15px 15px 0;
}

#collection-id .modal-body {
  padding: 20px;
}

#collection-id .title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

#collection-id button[data-target="#form"] {
  width: 100%;
  padding: 15px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  background-color: transparent;
  color: #666;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
}

#collection-id .collection-item {
  width: 100%;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  border: none;
  padding: 0;
}

#collection-id .collection-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#collection-id .collection-info {
  font-family: "Amiri", serif;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px;
}

#collection-id .collection-name {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

#collection-id .collection-count {
  font-size: 14px;
  opacity: 0.8;
  margin: 0;
}

/* Modal content padding */
.modal-content {
  padding: 20px;
  width: 100%;
}

/* Image section styling */
.modal-content2 .image-section {
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Form section styling */
.modal-content2 .form-section {
  padding-left: 20px;
}

/* Modal background color */
#form.modal.fade.show {
  background-color: rgba(0, 0, 0, 0.5);
  /* Optional: adds a semi-transparent background */
}

/* Modal dialog styling */
.modal-dialog {
  position: relative;
  width: auto;
  margin: 1.75rem auto;
}

.maktba {
  margin-top: -5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  font-family: "Amiri", serif;
  color: black;
}
.maktba-icon {
  margin: 5px;
  background-color: #FFD358;
  padding: 3px;
  font-size: 25px;
  border-radius: 50%;

}

/* Modal content styling */
.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 0.3rem;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0;
}

/* Heading styling */
h3 {

  text-align: center;
  padding: 10px;
  font-size: 24px;
  font-weight: bold;
  font-family: "Amiri", serif;
  font-style: italic;
  color: #002b45eb;
  line-height: 1.5;
  letter-spacing: 1px;
}

label {
  font-family: "Amiri", serif;
  color: #002b45eb;
  font-weight: bold;


}

/* Form input styling */
.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 30px;
  font-family: "Amiri", serif;
  margin-top: 5px;
  margin-bottom: -50px;
}


/* Modalttons styling */
.modal-button {
  font-family: "Amiri", serif;
  font-style: normal;
  font-size: small;
  display: flex;
  justify-content: center;
  gap: 5px;
  /* Adjust the value to control the space between buttons */
  margin-top: 20px;

  .btn2 {
    font-size: large;
    width: 100px;
    padding: 8px;
    border-radius: 6px;
    background-color: #002b45eb;
    color: white;
    text-align: center;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 4px;
  }

  .btn {
    width: 100px;
    font-size: large;
    text-align: center;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 4px;
  }
}



.success-message {
  background-color: #dff0d8;
  /* Couleur de fond */
  color: #3c763d;
  /* Couleur du texte */
  border: 1px solid #d6e9c6;
  /* Bordure */
  padding: 10px;
  /* Espacement intérieur */
  margin-top: 10px;
  /* Marge en haut */
  border-radius: 4px;
  /* Coins arrondis */
  font-family: "Amiri", serif;
}

/* styles.css */


/* Ajoutez ce style pour rendre le modal visible et le positionner correctement */
.modal.show {
  display: block;


}


#search-modal-id .modal-dialog {
  margin: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;

}

#search-modal-id .modal-content {

  border: none;
  border-radius: 0;
  width: 100%;
  height: 100%;

}


#search-modal-id .modal-header {
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
  border-bottom: none;

}

.modal-header label {
  padding-right: .5rem;
  padding-left: 0.5em;
  font-size: larger;
  background-color: rgb(207, 201, 201);
  border-radius: 5px;
  width: auto;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Amiri", serif;
  color: #000;
}

#search-modal-id .modal-body {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
  background-color: #fff;
  /* Change background color as needed */
}

#search-modal-id .modal-body {
  display: flex;
  flex-direction: column;
  padding-top: 2em;
  overflow-y: auto;


}



.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 5rem;
  width: 100%;
}

.image-item {
  align-items: center;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-family: "Amiri", serif;
  color: #000;
  font-size: 1.2rem;
}

.image-item img {
  width: 100%;
  height: 200px;
  display: block;
  border-radius: 0.5rem;
}


/* Responsive styling */
@media (max-width: 590px) {
  .row-image {
    justify-content: center;

  }
  .box7 img{
  width: 300px;
  /* ou la largeur que vous souhaitez */
  height: 220px;

  }
}



@media (max-width: 668px) {
  .modal-dialog {
    margin: 1rem auto;
    max-width: 90%;
  }

  .modal-content2 .form-section {
    padding-left: 0;
  }

  .modal-button {
    flex-direction: row;
    align-items: center;
  }

  .modal-button button:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 450px) {


  .post {
    font-size: smaller;
    font-style: normal;
    font-weight: bolder;
    font-family: "Amiri", serif;
    color: black;

  }

  h3 {
    font-size: 20px;
  }

  .form-input {
    font-size: 14px;
  }
}


@media screen and (max-width: 768px) {

  /* Icons */
  .box7 .icon {

    top: 12px;


  }

  .box7 .icon li a {
    width: 30px;
    height: 30px;
    font-size: 15px;
  }

}

